/*#boxes {
    content: "";
    display: table;
    clear: both;
    margin-left: auto;
    margin-right: auto;
  }*/

.boxes {
display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: center;
}

.div-hobbies {
    float: left;
    margin: 15px;
  }
