#boxes {

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    }

.div-awards {
    margin: 15px;
    text-align: center;
    }
