input {
  width: 100%;
  padding: 2%;
  border: 1px solid #ccc;
  border-radius: 4px;
  justify-content: center;
  margin: 2%;
  font-family: Arial;
}

textarea {
  width: 100%;
  padding: 2%;
  border: 1px solid #ccc;
  border-radius: 4px;
  justify-content: center;
  margin: 2%;
  font-family: Arial
}

.button-center {
  display: flex;
  justify-content: center;
}
