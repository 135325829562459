.text-shpere {
    position: relative;
    top: 0;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
}

.tagcloud {
    display: inline-block;
    top: 0;
    left: 0;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    letter-spacing: 0.0625em;
    font-size: 1.2em;
    text-align: center;
    margin: 20px
}

/* Change color of each text in sphere on hover   */
.tagcloud--item {
    color: #00ffffff;
    text-transform: uppercase;
}

.tagcloud--item:hover {
    color: #ff6347;
}

@media screen and (max-width: 768px) {
    .text-shpere {
        position: relative;
        top: 0;
        width: 75%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 0;
        overflow: hidden;
    }

    .tagcloud {
        display: flex;
        top: 0;
        left: 0;
        font-family: 'Inter', sans-serif;
        font-weight: 300;
        letter-spacing: 0.0625em;
        font-size: 0.9em;
        text-align: center;
        overflow: hidden;
    }
}