.accordion {
    border: 1px solid #ccc;
    margin: 10px;
  }
  
  .accordion-header {
    display: flex;
    justify-content: right;
    padding: 10px;
    cursor: pointer;
  }
  
  .arrow {
    transition: transform 0.3s ease;
    content: "read";
  }
  
  .expanded {
    transform: rotate(180deg);
  }
  