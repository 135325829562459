* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100vh;
}

.title {
  font-family: 'Times New Roman';
  margin: auto;
  margin: 2rem 0;
}

.title-white {
  font-family: 'Times New Roman';
  margin: auto;
  margin: 2rem 0;
  color: white;
}

.secondary-section {
  background: #b5b5b5;
}

section h2 {
  font-size: 3rem;
  margin-bottom: 2rem;
}
